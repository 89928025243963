import moment from 'moment';
import {
  copyObject,
} from '../../common/functions/helpers';

export default class AcaoConfiguracao {
  constructor(configuracao) {
    this.configuracao = configuracao;
  }

  get objeto() {
    return this.configuracao;
  }

  get idConfiguracao() {
    return this.configuracao.id;
  }

  get idAcaoTipo() {
    const { passo1 } = this.configuracao;
    return passo1.id;
  }

  get indDescricaoAutomatica() {
    const { passo3 } = this.configuracao;
    return passo3.indDescricaoAutomatica;
  }

  get indControleVerbaApenasPlanejamento() {
    const { passo1 } = this.configuracao;
    return passo1.indControleVerbaApenasPlanejamento;
  }

  get indVigenciaAutomatica() {
    const { passo4 } = this.configuracao;
    return passo4.indVigenciaAutomatica;
  }

  get indVigenciaTrimestral() {
    const { passo4 } = this.configuracao;
    return passo4.indVigenciaTrimestral;
  }

  get extensoesCarteira() {
    const { passo3 } = this.configuracao;
    return (passo3.listaCarteiraCliente || []).filter((c) => c.mnemonico !== 'UNIDADE_NEGOCIO' && c.habilitado);
  }

  get extensoesCarteiraLabel() {
    return this.extensoesCarteira.map((e) => e.label);
  }

  get unidadeNegocioCarteira() {
    const { passo3 } = this.configuracao;
    return !!((passo3.listaCarteiraCliente || [])
      .filter((c) => c.mnemonico === 'UNIDADE_NEGOCIO' && c.habilitado));
  }

  get temExtensaoCarteira() {
    return !!this.extensoesCarteira.length;
  }

  get descricaoAutomatica() {
    const { passo1 } = this.configuracao;
    return `${passo1.nome} - ${moment().format('DD/MM/YYYY')}`;
  }

  get relacaoCampos() {
    const { passo7 } = this.configuracao;
    const campos = passo7.listaPlanejamento.filter((campo) => !campo.excecao)
      .map((campo) => campo.label)
      .filter((l) => !this.extensoesCarteiraLabel.includes(l));
    return campos;
  }

  get excecaoCampos() {
    const { passo3 } = this.configuracao;
    const campos = passo3.listaProduto.filter((campo) => campo.excecao).map((campo) => campo.label);
    return campos;
  }

  get habilitaClientePagador() {
    const { passo3, passo6 } = this.configuracao;
    const { listaCliente } = passo3;
    const configPagador = listaCliente
      .filter((cfg) => cfg.label === 'cliente_pagador')[0];

    let permiteClientePagador = false;
    if (passo6 && passo6.liquidacao) {
      permiteClientePagador = passo6.liquidacao.indUnicaLiquidacao
        || passo6.liquidacao.indLiquidacaoManual;
    }

    return configPagador && configPagador.habilitado && permiteClientePagador;
  }

  get habilitaFornecedorPagador() {
    const { passo3, passo6 } = this.configuracao;
    const { listaFornecedor } = passo3;
    const configPagador = listaFornecedor
      .filter((cfg) => cfg.label === 'fornecedor_pagador')[0];

    let permiteFornecedorPagador = false;
    if (passo6 && passo6.liquidacao) {
      permiteFornecedorPagador = passo6.liquidacao.indUnicaLiquidacao
        || passo6.liquidacao.indLiquidacaoManual;
    }

    return configPagador && configPagador.habilitado && permiteFornecedorPagador;
  }

  get liquidacaoTemplateCalculo() {
    const { passo6 } = this.configuracao;
    if (passo6 && passo6.liquidacao && passo6.liquidacao.templateCalculo) {
      return passo6.liquidacao.templateCalculo;
    }
    return null;
  }

  get fornecedorPagadorObrigatorio() {
    const { passo3 } = this.configuracao;
    const { listaFornecedor } = passo3;
    const configPagador = listaFornecedor
      .filter((cfg) => cfg.label === 'fornecedor_pagador')[0];
    return configPagador ? configPagador.obrigatorio : false;
  }

  get focoAcao() {
    const { passo3 } = this.configuracao;
    const { listaCliente } = passo3;

    const habilitados = [];
    if ((listaCliente.find((item) => item.label === 'cliente') || {}).habilitado) {
      habilitados.push({
        valor: 'CLIENTE',
        i18n: 'label.foco_cliente',
        indiceRecursao: null,
      });
    }
    if ((listaCliente.find((item) => item.label === 'holding') || {}).habilitado) {
      habilitados.push({
        valor: 'HOLDING',
        i18n: 'label.holding',
        indiceRecursao: null,
      });
    }
    if ((listaCliente.find((item) => item.label === 'regional') || {}).habilitado) {
      habilitados.push({
        valor: 'REGIONAL',
        i18n: 'label.regional',
        indiceRecursao: null,
      });
    }
    const campoCliente = (listaCliente.find((item) => item.label === 'campo_cliente') || {});
    if (campoCliente.habilitado) {
      habilitados.push({
        valor: campoCliente.campoCliente.mnemonico,
        texto: campoCliente.campoCliente.descricao,
        i18n: `label.${campoCliente.campoCliente.label}`,
        indiceRecursao: campoCliente.campoCliente.indiceRecursao,
      });
    }
    return habilitados;
  }

  get origemAcao() {
    const { passo3 } = this.configuracao;
    const { listaFornecedor } = passo3;

    const habilitados = [];
    listaFornecedor.forEach((f) => {
      if (f.habilitado) {
        habilitados.push(f.label.toUpperCase());
      }
    });
    return habilitados;
  }

  get valorAcaoCalculadoAutomaticamente() {
    const { passo3 } = this.configuracao;
    return passo3.indCalculoAutomatico;
  }

  get calculoAutomatico() {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return !unicaConfig.indManual;
  }

  get meta() {
    const { passo5 } = this.configuracao;
    return passo5.apuracao.meta;
  }

  get templateCalculo() {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return unicaConfig.template;
  }

  get periodosApuracao() {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return unicaConfig.periodos;
  }

  get calculosApuracao() {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return unicaConfig.calculosApuracao;
  }

  get basesCalculo() {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return unicaConfig.bases;
  }

  get bonificacoes() {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return unicaConfig.bonificacoes;
  }

  get pagamentos() {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return unicaConfig.pagamentos;
  }

  get calculosBonificacao() {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return unicaConfig.calculosBonificacao;
  }

  get unidadesMedidas() {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return unicaConfig.unidadesMedida;
  }

  get listaIdVerbaInvestimento() {
    const { passo1 } = this.configuracao;
    const { investimentos } = passo1;
    return investimentos.map((i) => i.idOrcamentoVerbaInvestimento);
  }

  get uploadObrigatorioArquivo() {
    const { passo1 } = this.configuracao;
    return passo1.uploadObrigatorio;
  }

  get vinculosTiposVerba() {
    const vinculos = {};
    const { passo4 } = this.configuracao;
    passo4.listaVinculo.forEach((vinculoTipoVerba) => {
      const { idTipoVerba, vinculosCampos } = vinculoTipoVerba;
      vinculos[idTipoVerba] = vinculosCampos.map((v) => v.label);
    });
    return vinculos;
  }

  get descontos() {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return unicaConfig.descontos;
  }

  get indHabilitarCalculoRoi() {
    const { passo2 } = this.configuracao;
    return passo2.indHabilitarCalculoRoi;
  }

  get indPermitirClienteVisualizarRoi() {
    const { passo2 } = this.configuracao;
    return passo2.indPermitirClienteVisualizarRoi;
  }

  get indHabilitaContaCorrenteUnica() {
    const { passo1 } = this.configuracao;
    return passo1.indHabilitaContaCorrenteUnica;
  }

  get devolucoesHabilitadas() {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return unicaConfig.descontos
      && unicaConfig.descontos.length
      && unicaConfig.descontos.includes('DEVOLUCOES');
  }

  get temDescontoObrigatoria() {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return unicaConfig.descontosObrigatorios
      && unicaConfig.descontosObrigatorios.length;
  }

  ehDescontoObrigatorio(desconto) {
    const { passo5 } = this.configuracao;
    const unicaConfig = passo5.apuracao;
    return unicaConfig.descontosObrigatorios
      && unicaConfig.descontosObrigatorios.length
      && unicaConfig.descontosObrigatorios.includes(desconto);
  }

  aplicaParametrizacao(metadadosAcao) {
    const copiaMetadados = copyObject(metadadosAcao);
    this.parametrizaExtensoes(copiaMetadados);
    this.parametrizaCamposDinamicos(copiaMetadados);
    return copiaMetadados;
  }

  camposEdicaoAposAprovacao() {
    const { passo3 } = this.configuracao;
    const campos = [...passo3.listaDinamica
      .filter((c) => c.edicaoAprovado)
      .map((c) => c.label)];
    return campos;
  }

  campoPadraoEdicaoAposAprovado(campo) {
    const { passo3 } = this.configuracao;
    const campos = [...passo3.listaPadrao
      .filter((c) => c.edicaoAprovado)
      .map((c) => c.label)];

    return campos.includes(campo);
  }

  /**
   * [Parametriza Campos Opcionais]
   *
   * Cruza as informacoes da configuracao com a relacao
   * de campos opcionais, aplicando
   * especificos comprotamentos (ex: obrigatoriedade) da
   * configuracao - feita pelo usuário - ao campo original;
   *
   * @param {*} relacaoCampos
   * @param {*} contexto
   */
  parametrizaCamposOpcionais(relacaoCampos) {
    const { passo3 } = this.configuracao;
    const mapaConfiguracao = {};
    passo3.listaProduto
      .filter((campo) => ['produto'].indexOf(campo.label) >= 0)
      .forEach((campo) => {
        mapaConfiguracao[campo.label] = campo;
      });

    relacaoCampos
      .filter((campo) => mapaConfiguracao[campo.labelCampo] != null)
      .forEach((campo) => {
        const campoConfiguracao = mapaConfiguracao[campo.labelCampo];
        campo.vlrObrigatorio = campoConfiguracao.obrigatorio;
      });
  }

  /**
   * [Parametriza Campos Dinamicos]
   *
   * Cruza as informacoes da configuracao com os metadados,
   * aplicando especificos comportamentos (ex: obrigatoriedade)
   * da configuracao - feita pelo usuário - ao campo original;
   *
   * @param {*} metadados
   */
  parametrizaCamposDinamicos(metadados) {
    const { mapaCamposDinamicos } = metadados;
    if (!mapaCamposDinamicos
      || Object.keys(mapaCamposDinamicos).length === 0) return;

    const { listaDinamica } = this.configuracao.passo3;
    const mapaConfiguracao = {};
    listaDinamica
      .forEach((campo) => {
        mapaConfiguracao[campo.label] = campo;
      });

    const camposAmostragem = Object.keys(mapaCamposDinamicos);
    camposAmostragem
      .filter((chave) => mapaConfiguracao[chave] != null)
      .forEach((chave) => {
        const campoDinamico = mapaCamposDinamicos[chave], // eslint-disable-line
          campoConfiguracao = mapaConfiguracao[chave]; // eslint-disable-line

        // aplica modificacoes
        campoDinamico.vlrObrigatorio = campoConfiguracao.obrigatorio;
      });
  }

  /**
   * [Parametriza Extensoes]
   *
   * Cruza as informacoes da configuracao com os metadados,
   * aplicando especificos comportamentos (ex: obrigatoriedade)
   * da configuracao - feita pelo usuário - à extensao original;
   *
   * @param {*} metadados
   */
  parametrizaExtensoes(metadados) {
    const { mapaEntidades } = metadados;
    if (!mapaEntidades
      || Object.keys(mapaEntidades).length === 0) return;

    const { passo3 } = this.configuracao;
    const mapaConfiguracao = {};
    passo3.listaProduto
      .filter((campo) => ['produto'].indexOf(campo.label) < 0)
      .forEach((campo) => {
        mapaConfiguracao[campo.label] = campo;
      });

    const extensoesAmostragem = Object.keys(mapaEntidades);
    extensoesAmostragem
      .filter((chave) => mapaConfiguracao[chave] != null)
      .forEach((chave) => {
        const extensao = mapaEntidades[chave], // eslint-disable-line
          campoConfiguracao = mapaConfiguracao[chave]; // eslint-disable-line

        // aplica modificacoes
        extensao.depObrigatoria = campoConfiguracao.obrigatorio;
      });
  }
}
